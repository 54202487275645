import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

const StyledMap = styled.nav`
  position: relative;
  overflow: hidden;

  div[data-standalone-searchbox] {
    left: 8px;
    position: absolute;
    top: 8px;
  }

  @media ${device.sm} {
  }
`;

export default StyledMap;
