import React, { useContext } from 'react';
import styled from 'styled-components';

import ApplicationContext from '../../particles/context/ApplicationContext';

const ErrorStyles = styled.div`
  margin: 0 0 16px;
  padding: 16px;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.grey900 : props.theme.white};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid
    ${props =>
      props.mode === 'dark'
        ? props.theme.white
        : props.theme.secondary};
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};
  font-size: 14px;

  * {
    display: inline;
  }

  p {
    margin: 0;
  }

  span {
    margin-right: 4px;
  }
`;

const DisplayError = ({ children, title }) => {
  const { state } = useContext(ApplicationContext);

  if (!children) return null;

  return (
    <ErrorStyles
      className="error"
      key={`error-${children}`}
      mode={state.theme}
    >
      <span>Sorry,</span>
      <p>{`${title ? `'${title}'` : ``} ${children}`}</p>
    </ErrorStyles>
  );
};

export default DisplayError;
