export const checker = (arr, target) => {
  if (!arr) return null;
  if (!target) return null;
  return target.every(v => arr.includes(v));
};

export const sanatizeStringToArray = string => {
  if (!string) return null;
  return string
    .toString()
    .replace(',', '')
    .replace('(', '')
    .replace(')', '')
    .split(' ');
};

////

export function autoParagraph(html) {
  if (!html) return null;
  return '<p>' + html.split(/\n/).join('</p>\n<p>') + '</p>';
}

export function convertToSlug(text) {
  if (!text) return null;
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export function decodeHTML(html) {
  if (!html) return null;
  html = html.replace('amp;', '');
  return html.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? parseInt(result[1], 16) +
        ', ' +
        parseInt(result[2], 16) +
        ', ' +
        parseInt(result[3], 16)
    : null;
}

export function httpTohttps(html) {
  if (!html) return null;
  return html.replace('http://', 'https://');
}

export function removeDimensions(html) {
  if (!html) return null;
  html = html.replace(/width="[^"]*"/g, '');
  html = html.replace(/height="[^"]*"/g, '');
  return html;
}

export function removeOrphans(html) {
  if (!html) return null;
  return html.replace(/ ([^ ]*)$/, ' $1');
}

export function slugTitle(html) {
  if (!html) return null;
  html = html.replace('-', ' ');
  html = html.toLowerCase().split(' ');
  for (var i = 0; i < html.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    html[i] = html[i].charAt(0).toUpperCase() + html[i].substring(1);
  }
  // Directly return the joined string
  return html.join(' ');
}

export function randomString() {
  return Math.random()
    .toString(36)
    .substring(7);
}
