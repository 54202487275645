import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';
import { hexToRgb } from '../../../helpers';

const StyledMapFilters = styled.nav`
  max-width: 400px;
  max-height: 100%;
  overflow-y: scroll;
  padding: 25px;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.grey800 : props.theme.white};
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};

  @media ${device.sm} {
  }

  fieldset {
    margin: 0;
    margin-top: 4px;
    padding: 0;
    padding-top: 4px;

    border: none;
    border-top: 1px solid ${props => props.theme.grey600};

    + fieldset {
      margin-top: 4px;
    }

    label:first-of-type {
      margin: 0 0 8px;
    }
  }

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    width: 1px;
  }

  input[type='checkbox'] + label {
    align-items: center;
    display: flex;
    margin: 0px 0;
    padding-left: 4px;

    border: 1px solid transparent;
    transition: 0.2s all ease;

    &:before {
      content: '';
      display: block;
      height: 16px;
      margin-right: 16px;
      width: 16px;

      background-position: center;
      background-repeat: no-repeat;

      background-color: ${props =>
        props.mode === 'dark'
          ? `rgba(${hexToRgb(props.theme.grey400)}, 0.4)`
          : `rgba(${hexToRgb(props.theme.grey800)}, 0.4)`};
      border: ${props =>
        props.mode === 'dark'
          ? `2px solid rgba(${hexToRgb(props.theme.grey400)}, 0.4)`
          : `2px solid rgba(${hexToRgb(props.theme.grey800)}, 0.4)`};
      transition: 0.2s all ease;
    }

    &:focus,
    &:hover {
      background-color: ${props =>
        `rgba(${hexToRgb(props.theme.primary)}, 0.2)`};
      border: 1px solid
        ${props => `rgba(${hexToRgb(props.theme.primary)}, 0.2)`};

      &:before {
        background-color: ${props =>
          `rgba(${hexToRgb(props.theme.primary)}, 0.2)`};
        border: 2px solid
          ${props => `rgba(${hexToRgb(props.theme.primary)}, 0.2)`};
      }
    }
  }

  input[type='checkbox']:checked + label {
    background-color: ${props =>
      `rgba(${hexToRgb(props.theme.primary)}, 0.4)`};
    border: 1px solid
      ${props => `rgba(${hexToRgb(props.theme.primary)}, 0.4)`};

    &:before {
      background-image: ${props =>
        props.mode === 'dark'
          ? `url('${props.iconCheckWhite}')`
          : `url('${props.iconCheck}')`};
      background-color: ${props =>
        `rgba(${hexToRgb(props.theme.primary)}, 0.4)`};
      border: 2px solid
        ${props => `rgba(${hexToRgb(props.theme.primary)}, 0.4)`};
    }
  }

  p {
    font-size: 14px;
  }

  .form__actions {
    display: flex;
    justify-content: space-between;
  }

  .MuiSlider-valueLabel {
    font-size: 14px;
  }

  .map__filters__group {
    align-items: center;
    display: flex;

    label {
      width: 100%;

      cursor: pointer;
    }
  }
`;

export default StyledMapFilters;
