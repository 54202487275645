import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

const StyledMapTable = styled.div`
  overflow: scroll;
  overflow-y: scroll;
  left: 0;
  max-height: 100%;
  padding: 0 0 64px;
  position: absolute;
  top: 0;

  background-color: ${props =>
    props.mode === 'dark'
      ? props.theme.grey800
      : props.theme.grey400};
  color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.black};

  nav {
    align-items: center;
    display: flex;
    left: 0;
    padding: 12px 16px;
    position: sticky;
    top: 0;

    background-color: ${props =>
      props.mode === 'dark'
        ? props.theme.grey800
        : props.theme.grey400};

    * + * {
      margin-left: 8px;
    }

    a,
    button,
    .button {
      margin-bottom: 0;
      margin-top: 0;

      text-decoration: none;
      text-transform: initial;
    }

    .button--tertiary {
      color: ${props =>
        props.mode === 'dark'
          ? props.theme.grey500
          : props.theme.grey700};

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
        color: ${props =>
          props.mode === 'dark'
            ? props.theme.white
            : props.theme.black};
        transform: translateY(0);
      }
    }
  }

  table {
    border-collapse: collapse;
    margin: 0 16px;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    font-size: 14px;
    text-align: left;
    transition: 0.4s all ease;
  }

  @media ${device.MXmd} {
    tr,
    th,
    td {
      display: block;
    }
  }

  p {
    font-size: 14px;
  }

  td,
  th {
    min-width: 350px;
    padding: 16px 8px;
  }

  td {
    background-color: ${props =>
      props.mode === 'dark'
        ? props.theme.grey100
        : props.theme.white};
    border-top: 1px solid ${props => props.theme.grey400};
    transition: background-color 0.2s ease;

    &:before {
      @media ${device.MXmd} {
        content: attr(data-title) ': ';
      }
    }
  }

  th {
    background-color: ${props => props.theme.grey200};
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  tr {
    display: block;

    background-color: ${props => props.theme.white};
    border: none;

    &:nth-of-type(even) {
      td {
        background-color: ${props => props.theme.grey100};
      }
    }

    &:active,
    &:focus,
    &:hover {
      td {
        background-color: ${props => props.theme.grey300};
      }

      .table__actions * {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .table__actions {
    a {
      display: inline-block;
      color: ${props => props.theme.primary};
      padding: 0 4px;
      margin: 0 4px;
      text-decoration: none;
      & ~ a {
        position: relative;

        &:before {
          content: '';
          display: block;
          height: 50%;
          left: -4px;
          position: absolute;
          top: 25%;
          width: 1px;
          background-color: ${props => props.theme.grey400};
        }
      }

      &:active,
      &:focus,
      &:hover {
        color: ${props => props.theme.grey400};
      }

      & * {
        opacity: 0;
        transition: 0.2s opacity ease;
        visibility: hidden;
      }
    }
  }

  .table__currency {
    text-align: right;
  }

  .table__pagination {
    margin-right: auto;
    padding-left: 0;
    position: static;

    button {
      padding: 4px;

      background-color: transparent;
      box-shadow: none;
      color: ${props =>
        props.mode === 'dark'
          ? props.theme.grey500
          : props.theme.grey700};

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
        color: ${props =>
          props.mode === 'dark'
            ? props.theme.white
            : props.theme.black};
        transform: translateY(0);
      }
    }

    .button--active {
      color: ${props =>
        props.mode === 'dark'
          ? props.theme.white
          : props.theme.black};
      text-decoration: underline;
    }
  }

  table + .table__pagination {
    padding-left: 16px;
  }

  .map-meta {
    margin-right: 16px;

    color: ${props =>
      props.mode === 'dark'
        ? props.theme.grey500
        : props.theme.grey700};
  }
`;

export default StyledMapTable;
