import styled from 'styled-components';
// import { device } from '../../particles/MediaQueries';

const StyledMapMeta = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;

  top: 8px;
  padding: 12px 16px;
  position: absolute;
  right: 60px;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.grey800 : props.theme.white};
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};
  transition: 0.4s all ease;

  span {
    margin-left: 4px;
  }
`;

export default StyledMapMeta;
