import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const StyledSlider = styled.fieldset`
  .MuiSlider-root {
    color: ${props => props.theme.primary};
  }
`;

const useStyles = makeStyles({
  root: {
    fontSize: '16px',
      },
});

function valuetext(epcRating) {
  return `${epcRating} EPC Rating`;
}

const RangeSlider = ({ epcRating, isSubmitting, onChange }) => {
  const classes = useStyles();

  return (
    <StyledSlider className={classes.root}>
      <label>Efficiency Rating</label>
      <Slider
        value={epcRating}
        onChange={onChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={1}
        marks
      />
      <p>
        Move the ends of the slider to select a range of EPC rating
        values
      </p>
      <button
        disabled={isSubmitting}
        type="submit"
        value="Apply Filter"
      >
        Apply Filter
      </button>
    </StyledSlider>
  );
};

export default RangeSlider;
