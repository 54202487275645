import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

const StyledThemeToggler = styled.nav`
  display: flex;
  width: 100%;

  transition: 0.4s all ease;

  button {
    margin: 0;
    padding: 8px 12px;

    span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    background-color: ${props =>
      props.mode === 'dark'
        ? props.theme.grey800
        : props.theme.grey400};

    + button {
      margin-left: 8px;
    }

    &.button--active,
    &:focus,
    &:hover {
      background-color: ${props =>
        props.mode === 'dark'
          ? props.theme.grey400
          : props.theme.white};
    }
  }

  img {
    height: 20px;
  }

  @media ${device.sm} {
  }
`;

export default StyledThemeToggler;
