import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

const StyledMapInfo = styled.nav`
  max-height: calc(100% - 16px);
  max-width: 400px;
  overflow-y: scroll;
  padding: 32px;
  position: absolute;
  left: 8px;
  top: 8px;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.grey800 : props.theme.white};
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};
  opacity: 0;
  transition: 0.4s all ease;
  transform: translateX(-100%);

  @media ${device.sm} {
  }

  button {
    margin-top: 0;
  }

  &.map-info--open {
    opacity: 1;
    transform: translateX(0%);
  }

  .map-info__details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .map-info__additional {
    margin-top: 64px;
    padding-top: 16px;

    + .map-info__additional {
      padding-top: 48px;
    }

    > * {
      margin: 0;
      padding: 8px 0;
      + * {
        margin: 0 !important;

        border-top: 1px solid
          ${props =>
            props.mode === 'dark'
              ? props.theme.grey600
              : props.theme.grey400};
      }
    }

    h4 {
      margin-bottom: 16px;
    }
  }

  .map-info__comparison {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
    padding: 0;
    width: 100%;

    border-top: 0;

    .divider {
      display: block;
      margin-top: 16px;
      width: 10%;

      font-size: 20px;
      font-weight: 700;

      @media ${device.sm} {
        margin-top: 24px;
      }
    }
  }
`;

const StyledMapDetail = styled.div`
  align-items: center;
  display: ${props => (props.type === 'icon' ? 'flex' : 'block')};
  margin-top: 16px;
  width: 100%;

  font-size: 12px;

  @media ${device.sm} {
    margin-top: 24px;
    width: ${props =>
      props.size === 1 ? `22.5%` : props.size === 2 ? `45%` : `100%`};

    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin: 0;

    font-size: 14px;
    text-transform: capitalize;

    @media ${device.sm} {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 700;

    @media ${device.sm} {
      font-size: 20px;
    }
  }

  p {
    margin: 0;

    font-size: 12px;

    @media ${device.sm} {
      font-size: 16px;
    }
  }

  svg {
    margin-right: 8px;

    fill: ${props =>
      props.mode === 'dark'
        ? props.theme.grey600
        : props.theme.grey600};
    transition: 0.4s fill ease;

    &:hover {
      fill: ${props =>
        props.mode === 'dark'
          ? props.theme.white
          : props.theme.black};
    }
  }

  .info__focus {
    font-size: 14px;
    font-weight: 700;

    @media ${device.sm} {
      font-size: 18px;
    }
  }

  .info__sublabel {
    font-size: 12px;
    text-transform: capitalize;

    @media ${device.sm} {
      font-size: 14px;
    }
  }
`;

export { StyledMapDetail };

export default StyledMapInfo;
