import React, { useContext } from 'react';
import StyledSingleMapInfo from './MapInfoStyles';

import ApplicationContext from '../../particles/context/ApplicationContext';

const GOOGLE_MAP_API_KEY = process.env.GATSBY_GOOGLE_API_KEY;

const MapInfo = props => {
  const { state, dispatch } = useContext(ApplicationContext);

  if (!state.activeMarker) return null;

  const markerInfoArray =
    state && state.locationsQueried
      ? state.locationsQueried
      : state.locations && state.locations.length
      ? state.locations
      : [];

  const markerInfo =
    state.activeMarker && markerInfoArray.length
      ? markerInfoArray.find(entry => {
          return entry.ID === state.activeMarker.ID;
        })
      : undefined;

  if (!markerInfo) return null;

  return (
    <>
      <StyledSingleMapInfo
        {...props}
        mode={state.theme}
        className={
          state.activeMarker ? `map-info map-info--open` : `map-info`
        }
      >
        <button
          onClick={e => {
            e.preventDefault();
            dispatch({
              type: 'set',
              key: 'activeMarker',
              value: null,
            });
          }}
        >
          Close Info
        </button>

        {state.activeMarker && markerInfo && (
          <>
            <section className="map-info__details">
              <div>
                <b>{markerInfo.fulladdress}</b>
                <br />
                <br />
                <b>Current EPC rating: </b>
                {markerInfo.CURRENT_ENERGY_RATING} (
                {markerInfo.CURRENT_ENERGY_EFFICIENCY})
                <br />
                <b>Lodgement date: </b>
                {markerInfo.LODGEMENT_DATE}
                <br />
                <b>Assessment Type: </b>ECO assessment
                <br />
                <br />
                <u>
                  <b>
                    Street view image of approximate location
                    <br />
                  </b>
                </u>
                <a
                  href={`https://www.google.com/maps/search/${markerInfo.fulladdress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${markerInfo.fulladdress}&key=${GOOGLE_MAP_API_KEY}`}
                    alt={`Street view panaroma view of ${markerInfo.fulladdress}`}
                  />
                </a>
                <br />
                <br />
                <b>
                  <u>Property information</u>
                </b>
                <br />
                <b>Property type: </b>
                {markerInfo.PROPERTY_TYPE}
                <br />
                <b>Built form: </b>
                {markerInfo.BUILT_FORM}
                <br />
                <b>Total floor area: </b>58m<sup>2</sup>
                <br />
                <b>Floor level (if applicable): </b>
                {markerInfo.FLOOR_LEVEL}
                <br />
                <b>Storey (if applicable): </b>
                <br />
                <b>Wall type: </b>
                {markerInfo.WALLS_DESCRIPTION}
                <br />
                <b>Roof: </b>
                {markerInfo.ROOF_DESCRIPTION}
                <br />
                <br />
                <b>
                  <u>Heating information</u>
                </b>
                <br />
                <b>Current annual heating cost: </b>£
                {markerInfo.HEATING_COST_CURRENT}
                <br />
                <b>Main heating: </b>
                {markerInfo.MAINHEAT_DESCRIPTION}
                <br />
                <b>Mains gas: </b>
                {markerInfo.MAINS_GAS_FLAG}
                <br />
                <b>Main fuel: </b>
                {markerInfo.MAIN_FUEL}
                <br />
                <b>Secondary heating: </b>
                {markerInfo.SECONDHEAT_DESCRIPTION}
                <br />
                <br />
                <b>
                  <u>Other information</u>
                </b>
                <br />
                <b>Windows: </b>
                {markerInfo.WINDOWS_DESCRIPTION}
                <br />
                <b>Lighting: </b>
                {markerInfo.LIGHTING_DESCRIPTION}
                <br />
                <b>Low energy lighting: </b>
                {markerInfo.LOW_ENERGY_LIGHTING}
                <br />
                <b>Floor description: </b>
                {markerInfo.FLOOR_DESCRIPTION}
                <br />
                <br />
                <b>Geocoded address: </b>
                {markerInfo.Geocode_return_address}
                <br />
                <b>Geocode type: </b>
                {markerInfo.Status}
                <br />
                <br />
                <a
                  className="button button--secondary"
                  href="http://www.environmentcentre.com/maps/Open/About.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More information
                </a>
                <a
                  className="button button--secondary"
                  href="https://www.epcregister.com/reportSearchAddressByPostcode.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download a copy of the EPC
                </a>
              </div>
            </section>
          </>
        )}
      </StyledSingleMapInfo>
    </>
  );
};

export default MapInfo;
