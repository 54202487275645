import React, { useContext } from 'react';
import { CSVLink } from 'react-csv';
import StyledMapTable from './MapTableStyles';

import ApplicationContext from '../../particles/context/ApplicationContext';

const MapTable = props => {
  const { state, dispatch } = useContext(ApplicationContext);

  if (state.tableView !== true) return null;

  const markerInfoArray =
    state && state.locationsQueried
      ? state.locationsQueried
      : state.locations && state.locations.length
      ? state.locations
      : [];

  const closeTable = e => {
    e.preventDefault();
    const key = 'tableView';
    const value = false;
    dispatch({ type: 'set', key, value });
  };

  const { tableIndex } = state;
  const recordsPerView = 100;

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? '0' + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getDate()),
      pad(d.getMonth() + 1),
      d.getFullYear(),
    ].join('-');
  }

  const dateString = convertDate(new Date());

  return (
    <StyledMapTable {...props} mode={state.theme}>
      <nav>
        <TablePagination markerInfoArray={markerInfoArray} />
        <span className="map-meta">
          {state.locationsQueried
            ? `Query returned ${state.locationsQueried.length} records`
            : `You haven't created a filtered search yet, the table contains all records`}
        </span>
        <CSVLink
          className="button button--primary"
          data={markerInfoArray}
          filename={`${dateString}-all-data.csv`}
        >
          Download All Table Data
        </CSVLink>
        <button
          className="button button--tertiary"
          onClick={closeTable}
        >
          Close Table
        </button>
      </nav>
      <table>
        <thead>
          <tr>
            {Object.keys(markerInfoArray[0]).map(objKey => (
              <th key={`th-${objKey}`}>
                {objKey.replace(/_/g, ' ').toLowerCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {markerInfoArray
            .slice(
              tableIndex * recordsPerView,
              tableIndex * recordsPerView + recordsPerView,
            )
            .map(dataPoint => (
              <tr key={dataPoint.ID}>
                {Object.keys(dataPoint).map(objKey => (
                  <MapTableCell
                    key={`${objKey}-${dataPoint[objKey]}`}
                    dataPoint={dataPoint}
                    objKey={objKey}
                  />
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <TablePagination markerInfoArray={markerInfoArray} />
    </StyledMapTable>
  );
};

const TablePagination = ({ markerInfoArray }) => {
  const { state, dispatch } = useContext(ApplicationContext);

  if (!markerInfoArray) return null;

  const { tableIndex } = state;
  const recordsPerView = 100;

  const count = Math.ceil(markerInfoArray.length / recordsPerView);

  const paginationUpdate = (e, action) => {
    e.preventDefault();

    if (action === 'previous') {
      const key = 'tableIndex';
      const value = tableIndex - 1;
      dispatch({ type: 'set', key, value });
    }

    if (action === 'next') {
      const key = 'tableIndex';
      const value = tableIndex + 1;
      dispatch({ type: 'set', key, value });
    }
  };

  return (
    <nav className="table__pagination">
      <button
        className="button"
        disabled={state.tableIndex === 0}
        onClick={e => {
          paginationUpdate(e, 'previous');
        }}
      >
        Previous
      </button>
      <span>Viewing Page {tableIndex + 1}</span>
      <button
        className="button"
        disabled={tableIndex === count - 1}
        onClick={e => {
          paginationUpdate(e, 'next');
        }}
      >
        Next
      </button>
    </nav>
  );
};

const MapTableCell = ({ dataPoint, objKey }) => {
  return (
    <td>
      <p>{dataPoint[objKey]}</p>
    </td>
  );
};

export default MapTable;
