import React, { useContext } from 'react';
import StyledMapMeta from './MapMetaStyles';

import ApplicationContext from '../../particles/context/ApplicationContext';

const MapMeta = props => {
  const { state } = useContext(ApplicationContext);

  return (
    <StyledMapMeta className="map-meta">
      <span className="map-meta__field">
        {state.locationsQueried &&
        state.locationsQueried.length > 5000
          ? `Viewing first 5000 records of ${state.locationsQueried.length} records`
          : state.locationsQueried
          ? `Viewing ${state.locationsQueried.length} records`
          : `You haven't created a filtered search yet, limitting to first 1000 rows until a search is made.`}
      </span>
    </StyledMapMeta>
  );
};

export default MapMeta;
