import React, { useContext, useEffect, useRef } from 'react';
import useWhyDidYouUpdate from '../../../useWhyDidYouUpdate';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
} from 'react-google-maps';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';

import iconMarker from './MarkerIcon.png';
import StyledMap from './Map-Styles';

import ThemeDefault from './themes/default.json';
import ThemeLunar from './themes/lunar.json';

import { DBServiceAll } from '../../particles/DBService/DBService';
import ApplicationContext from '../../particles/context/ApplicationContext';

import Loader from '../../molecules/loader/Loader';
import MapInfo from '../../molecules/map-info/MapInfo';
import MapMeta from '../../molecules/map-meta/MapMeta';
import MapTable from '../../molecules/map-table/MapTable';

const GOOGLE_MAP_API_KEY = process.env.GATSBY_GOOGLE_API_KEY;

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <Loader />,
    containerElement: (
      <div style={{ height: `100%`, width: `100%` }} />
    ),
    mapElement: <div style={{ height: `100%`, width: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  const { markers } = props;
  useWhyDidYouUpdate('MapWith', props);

  const start = {
    lat: 50.921429,
    lng: -1.398396,
  };

  return (
    <GoogleMap
      center={props.mapCenter ? props.mapCenter : start}
      defaultZoom={13}
      defaultCenter={start}
      defaultOptions={{
        mapTypeControl: true,
    mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.RIGHT_TOP,
        rotateControl: true
    },
        styles: props.theme === 'dark' ? ThemeLunar : ThemeDefault,
      }}
    >
      {props.KmlLayer &&
        props.KmlLayer.length > 0 &&
        props.KmlLayer.map(
          area =>
            area[0] &&
            area[0].lat &&
            area[0].lng && (
              <Polygon
                key={`${area[0].lat}-${area[0].lng}`}
                path={area}
                options={{
                  fillColor: '#badc58',
                  strokeColor: '#badc58',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ),
        )}
      <MapSearch />
      <MapClusterCollection
        clusterClick={props.clusterClick}
        markerClick={props.markerClick}
        markers={markers}
      />
    </GoogleMap>
  );
});

const MapSearch = () => {
  const { dispatch } = useContext(ApplicationContext);

  let searchboxRef = useRef(null);

  const onSearchBoxMounted = ref => {
    searchboxRef = ref;
  };

  const onPlacesChanged = () => {
    if (!searchboxRef) return null;
    const places = searchboxRef.getPlaces();

    if (!places || !places[0] || !places[0].geometry) return null;

    const mapCenter = {
      lat: places[0].geometry.location.lat(),
      lng: places[0].geometry.location.lng(),
    };

    dispatch({
      type: 'set',
      key: 'mapCenter',
      value: mapCenter,
    });
  };

  return (
    <div data-standalone-searchbox="">
      <StandaloneSearchBox
        ref={onSearchBoxMounted}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search for an address..."
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </StandaloneSearchBox>
    </div>
  );
};

const MapClusterCollection = ({
  clusterClick,
  markerClick,
  markers,
}) => {
  useWhyDidYouUpdate('Cluster', markers);
  return (
    <>
      {markers.map(marker => (
        <Marker
          icon={iconMarker}
          key={marker.ID}
          noRedraw
          onClick={e => markerClick(e, marker)}
          position={{
            lat: parseFloat(marker.Latitude),
            lng: parseFloat(marker.Longitude),
          }}
        />
      ))}
    </>
  );
};

const Map = props => {
  const { state, dispatch } = useContext(ApplicationContext);
  useWhyDidYouUpdate('Map', state);

  useEffect(() => {
    async function fetchData() {
      const indexedDBRecords = await DBServiceAll();

      dispatch({
        type: 'set',
        key: 'locations',
        value: indexedDBRecords,
      });
    }
    /* Hi */
    fetchData();
  }, []);

  const clusterClick = React.useCallback(
    e => {
      dispatch({
        type: 'set',
        key: 'activeMarker',
        value: null,
      });
    },
    [dispatch],
  );

  const markerClick = React.useCallback(
    (e, marker) => {
      dispatch({
        type: 'set',
        key: 'activeMarker',
        value: marker,
      });

      // Set Geolocation to Context state
      const { Latitude, Longitude } = marker;
      const key = 'mapCenter';
      const value = {
        lat: parseFloat(Latitude),
        lng: parseFloat(Longitude),
      };
      dispatch({ type: 'set', key, value });
    },
    [dispatch],
  );

  if (state.tableView === true) return <MapTable />;

  const markers =
    state && state.locationsQueried
      ? state.locationsQueried.slice(0, 5000)
      : state.locations && state.locations.length
      ? state.locations.slice(0, 1000)
      : [];

  const { KmlLayer } = state;

  return (
    <StyledMap>
      {markers.length > 0 && (
        <MapWithAMarkerClusterer
          markers={markers}
          clusterClick={clusterClick}
          KmlLayer={KmlLayer}
          markerClick={markerClick}
          mapCenter={state.mapCenter}
          theme={state.theme}
        />
      )}
      <MapMeta />
      <MapInfo />
    </StyledMap>
  );
};

export default Map;
