import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { compose } from 'recompose';

import * as CONFIG from '../../SiteConfig';

import DotPattern from '../assets/images/patterns/dot-grid.png';

import { DBServiceAll } from '../components/particles/DBService/DBService';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/particles/Session';
import * as ROUTES from '../components/particles/constants/routes';

import Loader from '../components/molecules/loader/Loader';
import MapFilters from '../components/molecules/map-filters/Map-Filters';

import Map from '../components/organisms/map/Map';

import Layout from '../components/templates/Default';

const MapContainer = styled.section`
  display: flex;
  flex-grow: 1;
  max-height: 95vh;
  max-height: calc(100vh - 44px);
  width: 100%;

  > * {
    flex: 1;
  }
`;

const NoDataWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;

  background-image: url(' ${DotPattern} ');

  > * {
    max-width: 800px;
  }

  /* IE Fix */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;

    > * {
      max-width: 1000px;
    }
  }
`;

const NoDataFound = () => (
  <NoDataWrapper className="grid grid--short">
    <h1 className="h4">Sorry, we couldn't find any data to use.</h1>
    <p>
      <Link to={ROUTES.INSTALL}>
        Head over to the install page to import your CSV file.
      </Link>
    </p>
  </NoDataWrapper>
);

const HomePageBase = props => {
  const [databaseInstalled, setDatabaseInstalled] = useState(false);
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    const queryDatabase = async () => {
      const records = await DBServiceAll();
      if (records.length) setDatabaseInstalled(true);
      setSearched(true);
    };
    queryDatabase();
  }, []);

  return (
    <MapContainer className="map-container">
      {!searched ? (
        <Loader>Searching database...</Loader>
      ) : (
        <>
          {databaseInstalled ? (
            <>
              <MapFilters />
              <Map {...props} />
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </MapContainer>
  );
};

const condition = authUser => !!authUser;

const HomePage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePageBase);

const HomePageWrapper = props => {
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV ||
    process.env.NODE_ENV ||
    'development';

  return (
    <Layout page="homepage" align="center">
      <Helmet
        title={`${CONFIG.CONFIG.SITE_TITLE} | Homepage`}
        defer={false}
      />
      {activeEnv === `production` ? (
        <HomePage {...props} />
      ) : (
        <HomePageBase {...props} />
      )}
    </Layout>
  );
};

export default HomePageWrapper;
