import React, { useContext, useRef } from 'react';
import { Formik, FieldArray } from 'formik';
import xml2js from 'xml2js';

import StyledMapFilters from './Map-Filters-Styles';
import RangeSlider from './RangeSlider';
import ThemeToggler from './ThemeToggler';

import IconCheck from '../../../assets/icons/check.svg';
import IconCheckWhite from '../../../assets/icons/check-white.svg';
import EPCImage from '../../../assets/images/epc-rating.png';

import ApplicationContext from '../../particles/context/ApplicationContext';

import Error from '../../molecules/error/Error';

import OptionsBuiltForm from './options/BuiltForm.json';
import OptionsFloorLevel from './options/FloorLevel.json';
import OptionsGlazingType from './options/GlazingType.json';
import OptionsMainsGasConnection from './options/MainsGasConnection.json';
import OptionsMainHeatingFuel from './options/MainHeatingFuel.json';
import OptionsMainHeatingType from './options/MainHeatingType.json';
import OptionsPropertyType from './options/PropertyType.json';
import OptionsRoofType from './options/RoofType.json';
import OptionsTransactionType from './options/TransactionType.json';
import OptionsWallType from './options/WallType.json';
import OptionsLocalAuthority from './options/LocalAuthority.json';


const MapFilters = props => {
  const { state, dispatch } = useContext(ApplicationContext);

  return (
    <StyledMapFilters
      {...props}
      className="map__filters"
      iconCheck={IconCheck}
      iconCheckWhite={IconCheckWhite}
      mode={state.theme}
    >

      
      <h1 className="h4">EPC Mapping Tool</h1>
      <p>Map showing example EPC data. Select tick boxes, move the slider and search for addresses to show properties which meet specific criteria.</p>
      <p><i> Note that all data is fictional for demonstration purposes and does not apply to these randomly generated addresses in any way.</i></p>
      <p><b>Background layer contains Ordnance Survey data © Crown copyright and database right 2016</b></p>
      <p></p>
            
      
      <Formik
        enableReinitialize
        validate={values => {
          let errors = {};
          Object.keys(values).forEach((key, index) => {
            const value = values[key];
            if (value.length === 0)
              errors[key] = `must have one checkbox selected.`;
          });
          return errors;
        }}
        initialValues={{
          builtForm:
            state && state.builtForm ? state.builtForm : ['all'],
          floorLevel:
            state && state.floorLevel ? state.floorLevel : ['all'],
          gasConnection:
            state && state.gasConnection
              ? state.gasConnection
              : ['all'],
          glazingType:
            state && state.glazingType ? state.glazingType : ['all'],
          heatingFuel:
            state && state.heatingFuel ? state.heatingFuel : ['all'],
          heatingType:
            state && state.heatingType ? state.heatingType : ['all'],
          propertyType:
            state && state.propertyType
              ? state.propertyType
              : ['all'],
          roofType:
            state && state.roofType ? state.roofType : ['all'],
          transactionType:
            state && state.transactionType
              ? state.transactionType
              : ['all'],
          wallType:
            state && state.wallType ? state.wallType : ['all'],
              localAuthority:
            state && state.localAuthority ? state.localAuthority : ['southampton'],
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch({ type: 'setMultiple', values });
          dispatch({ type: 'loading' });
          dispatch({ type: 'query' });
          setSubmitting(false);
        }}
        render={({
          errors,
          handleSubmit,
          isSubmitting,
          resetForm,
          values,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <nav className="form__actions">
              <button type="submit" value="Apply Filter">
                Apply Filter
              </button>
              <button
                className="button--table button--secondary"
                onClick={e => {
                  e.preventDefault();
                  const key = 'tableView';
                  const value = state.tableView
                    ? !state.tableView
                    : true;
                  dispatch({ type: 'set', key, value });
                }}
              >
                {state.tableView ? `Hide` : `View`} Table
              </button>
            </nav>
            <fieldset>
              <img src={EPCImage} alt="Chart showing EPC ratings" />
              <RangeSlider
                defaultValue={[0, 100]}
                epcRating={state.epcRatings}
                onChange={(event, newValue) => {
                  dispatch({
                    type: 'set',
                    key: 'epcRatings',
                    value: newValue,
                  });
                }}
              />
            </fieldset>
            <FieldArray
              name="wallType"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="wallType"
                  isSubmitting={isSubmitting}
                  options={OptionsWallType}
                  title="Main wall type"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="gasConnection"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="gasConnection"
                  isSubmitting={isSubmitting}
                  options={OptionsMainsGasConnection}
                  title="Gas connection"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="heatingFuel"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="heatingFuel"
                  isSubmitting={isSubmitting}
                  options={OptionsMainHeatingFuel}
                  title="Main heating fuel"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="heatingType"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="heatingType"
                  isSubmitting={isSubmitting}
                  options={OptionsMainHeatingType}
                  title="Main heating type"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="roofType"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="roofType"
                  isSubmitting={isSubmitting}
                  options={OptionsRoofType}
                  title="Roof type"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="propertyType"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="propertyType"
                  isSubmitting={isSubmitting}
                  options={OptionsPropertyType}
                  title="Property type"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="builtForm"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="builtForm"
                  isSubmitting={isSubmitting}
                  options={OptionsBuiltForm}
                  title="Built form"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="glazingType"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="glazingType"
                  isSubmitting={isSubmitting}
                  options={OptionsGlazingType}
                  title="Glazing type"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="transactionType"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="transactionType"
                  isSubmitting={isSubmitting}
                  options={OptionsTransactionType}
                  title="Transaction type"
                  type="checkbox"
                  values={values}
                />
              )}
            />
            <FieldArray
              name="floorLevel"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="floorLevel"
                  isSubmitting={isSubmitting}
                  options={OptionsFloorLevel}
                  title="Floor level"
                  type="checkbox"
                  values={values}
                />
                
              )}
            />
            <FieldArray
              name="localAuthority"
              render={arrayHelpers => (
                <Field
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  id="localAuthority"
                  isSubmitting={isSubmitting}
                  options={OptionsLocalAuthority}
                  title="Local Authority"
                  type="checkbox"
                  values={values}
                />
                
              )}
            />
            
            <fieldset>
              <label htmlFor="addressSearch">Address Filter</label>
              <input
                id="addressSearch"
                name="addressSearch"
                type="search"
                onChange={e => {
                  dispatch({
                    type: 'set',
                    key: 'addressSearch',
                    value: e.target.value,
                  });
                }}
                value={state.addressSearch}
              />
            </fieldset>
            
            
          </form>
        )}
      />
      <UploadKML />
    </StyledMapFilters>
  );
};

const Field = ({
  arrayHelpers,
  errors,
  id,
  isSubmitting,
  options,
  title,
  type,
  values,
}) => {
  const isChecked = (id, option) => {
    if (!Array.isArray(option.value))
      return values[id].includes(option.value);

    const matchedArray = option.value.some(val => {
      const valuesArray = values[id].filter(val =>
        Array.isArray(val),
      );

      if (!valuesArray.length) return false;

      const matched = valuesArray[0].includes(val);
      return matched;
    });
    return matchedArray;
  };

  return (
    <fieldset>
      {errors[id] && <Error title={title}>{errors[id]}</Error>}
      <label htmlFor={id}>{title}</label>
      {options.map(option => (
        <div
          className="map__filters__group"
          key={`${id}-${option.label}-${option.value}`}
        >
          <input
            type="checkbox"
            name={id}
            id={`${id}-${option.label}`}
            value={option.value}
            checked={isChecked(id, option)}
            onChange={e => {
              if (e.target.checked) arrayHelpers.push(option.value);
              else {
                const idx = values[id].indexOf(option.value);
                arrayHelpers.remove(idx);
              }
            }}
          />
          <label htmlFor={`${id}-${option.label}`}>
            {option.label}
          </label>
        </div>
      ))}
      <button
        disabled={isSubmitting}
        type="submit"
        value="Apply Filter"
      >
        Apply Filter
      </button>
    </fieldset>
  );
};

const findVal = (object, key) => {
  let value;
  Object.keys(object).some(k => {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findVal(object[k], key);
      return value !== undefined;
    }
    return false;
  });
  return value;
};

const UploadKML = () => {
  const kmlRef = useRef(null);
  const { dispatch } = useContext(ApplicationContext);

  const onFileChange = e => {
    const key = 'KmlLayer';
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function() {
      const data = reader.result;
      xml2js.parseString(data, (err, result) => {
        if (err) console.error(err);
        if (
          !result ||
          !result.kml ||
          !result.kml.Document[0] ||
          !result.kml.Document[0].Placemark
        )
          return null;

        const coordinates = result.kml.Document[0].Placemark.map(
          place => {
            const geometry = findVal(place, 'coordinates');
            if (geometry.length === 0) return;
            const string = geometry[0].split('\n');
            return string;
          },
        );
        const value = coordinates.map(c => {
          if (c.length === 0) return;
          const trimmed = c.map(item => item.trim());
          let positions = trimmed.map(plot => {
            if (plot && plot !== '') {
              const latLng = plot.split(`,`);

              return {
                lat: parseFloat(latLng[1]),
                lng: parseFloat(latLng[0]),
              };
            }
          });
          positions = positions.filter(el => {
            return el != null;
          });
          return positions;
        });
        dispatch({
          type: 'set',
          key,
          value,
        });
      });
    };
    reader.readAsText(file);
  };

  const clearKML = e => {
    e.preventDefault();
    const key = 'KmlLayer';
    const value = null;
    if (kmlRef) kmlRef.current.value = null;
    dispatch({ type: 'set', key, value });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <label htmlFor="kml">Upload KML file</label>
      <input
        id="kml"
        name="kml"
        onChange={onFileChange}
        ref={kmlRef}
        type="file"
      ></input>
      <button
        className="button button--secondary"
        onClick={clearKML}
        type="button"
      >
        Clear KML
      </button>
    </form>
    
  );
};

      


export default MapFilters;

